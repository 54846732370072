<template>
  <div class="row">
    <div class="col-md-12">
      <b-form @submit.stop.prevent="formOnSubmit" enctype="multipart/form-data">
        <b-card-body title="Setelan slide banner">
          <b-form-group
            id="input-group-title"
            label="Nama slide banner (tidak ditampilkan):"
            label-for="input-title"
          >
            <b-form-input
              readonly
              id="input-title"
              v-model="form.title"
              placeholder="Nama slide banner"
            ></b-form-input>
            <small class="text-danger">{{ error.title }}</small>
          </b-form-group>

          <b-form-group id="input-group" label="Gambar" label-for="input">
            <div class="image-input-wrapper">
              <img
                :src="uploadedImageUrl == '' ? form.image : uploadedImageUrl"
                alt=""
              />
            </div>
          </b-form-group>

          <!-- <b-form-group
            id="input-group-image"
            label="Gambar (800 x 600 piksel):"
            label-for="input-image"
          >
            <b-form-file
              v-model="form.image"
              placeholder="Upload Gambar"
              drop-placeholder="Letakkan file di sini..."
              @change="onFileUpload"
            ></b-form-file>
            <small class="text-danger">{{ error.image }}</small>
          </b-form-group> -->

          <b-form-group
            id="input-group-text-1"
            label="Teks 1 (atas, ukuran huruf kecil):"
            label-for="input-text-1"
          >
            <b-form-input
              readonly
              id="input-text-1"
              v-model="form.text_1"
              placeholder="Teks atas"
            ></b-form-input>
            <small class="text-danger">{{ error.text_1 }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-text-2"
            label="Teks 2 (tengah, ukuran huruf besar):"
            label-for="input-text-2"
          >
            <b-form-input
              readonly
              id="input-text-2"
              v-model="form.text_2"
              placeholder="Teks tengah"
            ></b-form-input>
            <small class="text-danger">{{ error.text_2 }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-text-3"
            label="Teks 3 (bawah, deskripsi):"
            label-for="input-text-3"
          >
            <b-form-input
              readonly
              id="input-text-3"
              v-model="form.text_3"
              placeholder="Teks bawah"
            ></b-form-input>
            <small class="text-danger">{{ error.text_3 }}</small>
          </b-form-group>

          <!-- Input text -->
          <b-form-group
            id="input-group-order"
            label="Urutan:"
            label-for="input-order"
          >
            <b-form-input
              readonly
              id="input-order"
              v-model="form.order"
              placeholder="Urutan"
            ></b-form-input>
            <small class="text-danger">{{ error.order }}</small>
          </b-form-group>

          <!-- Input text -->
          <b-form-group>
            <b-form-checkbox id="input-button" v-model="form.button" switch disabled>
              Tampilkan Button <b>({{ form.button ? "Ya" : "Tidak" }})</b>
            </b-form-checkbox>
          </b-form-group>

          <!-- Input text -->
          <b-form-group>
            <b-form-checkbox id="input-status" v-model="form.status" switch disabled>
              Aktifkan <b>({{ form.status ? "Ya" : "Tidak" }})</b>
            </b-form-checkbox>
          </b-form-group>

          <!-- Button -->
          <b-button type="button" variant="primary" @click="btnEditOnClick(form.id)">Edit</b-button>
          <b-button class="ml-2" variant="danger" type="button" @click="btnCancelOnClick">
            Tutup
          </b-button>
        </b-card-body>
      </b-form>
    </div>
  </div>
</template>

<style scoped>
.image-input-wrapper {
  width: 200px !important;
  height: 150px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    form: Object,
    route: String,
  },

  data() {
    return {
      // Error
      error: {
        title: "",
        text_1: "",
        text_2: "",
        text_3: "",
        position: "",
        status: "",
        image: "",
      },
      // Other
      uploadedImageUrl: "",
    };
  },

  methods: {
    onFileUpload(evt) {
      this.uploadedImageUrl = URL.createObjectURL(evt.target.files[0]);
      this.form.image = evt.target.files[0];
    },

    btnCancelOnClick(){
      this.$root.$emit('cancelAddWebsiteSlide');
    },

    async btnEditOnClick(id){
      let webSlide = await module.get('website-slides/' + id)
      // If Data Not Found
      if (webSlide == null) {
        // Redirect To List
        // this.$router.push('/donors')
      } else {
        this.$root.$emit('getWebsiteSlideById', webSlide);
      }
    },

    async formOnSubmit() {
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        if (key === "status") {
          formData.append(key, value ? 1 : 0);
        }
        else if(key === "button"){
          formData.append(key, value ? 1 : 0);
        }
        else {
          formData.append(key, value);
        }
      }
      let response = await module.submit(formData, this.route);
      if (response.state == "error") {
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        this.uploadedImageUrl = ""
        Swal.fire(response.success.title, response.success.message, "success");
        this.$root.$emit('updateWebsiteSlide');
      }
    },
  },
};
</script>
